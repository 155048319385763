<template>
   <main id="account-screen" class="main">

      <div class="breadcrumb">
         <div class="text">Account</div>
      </div>

      <div class="container">
         <ul class="list-group">
            <router-link :to="'/account/info'" class="list-group-item">
               <i class="fas fa-address-card icon"></i> {{ __('Dettagli account') }}
            </router-link>
            <router-link :to="'/account/ordini'" class="list-group-item">
               <i class="fas fa-cart-arrow-down icon"></i> {{ __('Ordini') }}
            </router-link>
            <router-link :to="'/account/fatturazione'" class="list-group-item">
               <i class="fas fa-credit-card icon"></i> {{ __('Dati di Fatturazione') }}
            </router-link>
            <!-- <router-link :to="'/account/spedizione'" class="list-group-item">
               <i class="fas fa-truck icon"></i> Dati di Spedizione
            </router-link> -->
            <li class="list-group-item" v-on:click="logout">
               <i class="fas fa-sign-out-alt icon"></i> {{ __('Logout') }}
            </li>
         </ul>
      </div>
   </main>
</template>

<script scoped>
import { Utils } from '@/inc/Utils';
import { mapActions } from "vuex";

export default{
   name: 'Account',
   mixins: [Utils],
   methods: {
      ...mapActions('account', ['get_user','logout'])
   },
   created(){
      this.get_user();
   }
}
</script>

<style scoped>
.list-group .icon{
   width: 20px;
   margin-right: 7px;
   text-align: center;
}
.list-group-item {
    padding: 0.75rem 1.25rem;
    border: unset;
}
</style>